import { ArticleDetailsDto } from '../../article/dtos/article-details.dto';
import { Transform, Type } from 'class-transformer';
import { Timestamp } from '@angular/fire/firestore';

const MIN_STOCK = 5;

export class ShopArticleDto {
  @Type(() => ArticleDetailsDto)
  article: ArticleDetailsDto;

  stock: number;

  @Type(() => Timestamp)
  @Transform(({ value }) => (value as Timestamp).toDate(), { toClassOnly: true })
  refilled: Date;

  get isLowStock(): boolean {
    return this.stock < MIN_STOCK;
  }
}
