import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoginResponseDto } from '../dtos/login-response.dto';
import { UserDto } from '../../user/dtos/user.dto';
import { Auth, signInWithEmailAndPassword } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private data: LoginResponseDto | null;

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected fireAuth: Auth,
  ) {
    fireAuth.onAuthStateChanged(async (user) => {
      if (!this.data && user) {
        const dto = new LoginResponseDto();
        dto.user = UserDto.fromFirebase(user)
        this.data = dto;
      }

      if (this.data && !user) {
        await this.router.navigate(['/login']);
      }
    });
  }

  async login(email: string, password: string): Promise<LoginResponseDto> {
    const result = await signInWithEmailAndPassword(this.fireAuth, email, password);

    const claims = await result.user.getIdTokenResult();
    const isAdmin = !!claims.claims['admin'];
    if (!isAdmin) {
      await this.fireAuth.signOut();
      throw new Error('Not an admin');
    }

    const dto = new LoginResponseDto();
    dto.user = UserDto.fromFirebase(result.user)

    return dto;
  }

  async logout() {
    await this.fireAuth.signOut();
    await this.router.navigate(['/login']);
  }

  get isLoggedIn(): boolean {
    return !!this.fireAuth.currentUser
  }

  get user(): UserDto | undefined {
    return this.data?.user;
  }
}
