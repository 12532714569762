import { BaseDto } from '../../core/dtos/base.dto';
import { Transform, Type } from 'class-transformer';
import { Timestamp } from '@angular/fire/firestore';
import { ArticleType } from '../enums/article-type.enum';
import { AddonType } from '../enums/addon-type.enum';

export class ArticleDetailsDto extends BaseDto {
  type: ArticleType;

  addonType: AddonType | null;

  name: string;

  description: string;

  preparationInstruction: string;

  ingredients: string;

  imagePath?: string | null;

  price: number;

  calories?: number | null;

  @Type(() => Timestamp)
  @Transform(({ value }) => (value as Timestamp).toDate())
  created: Date;

  @Type(() => Timestamp)
  @Transform(({ value }) => (value as Timestamp).toDate())
  updated: Date;
}
