import { BaseDto } from '../../core/dtos/base.dto';
import { Transform, Type } from 'class-transformer';
import { Timestamp } from '@angular/fire/firestore';

export class ClientDetailsDto extends BaseDto {
  email: string;
  name?: string;
  address?: string;
  city?: string;
  postalCode?: string;

  hasBlender?: boolean;

  @Type(() => Timestamp)
  @Transform(({ value }) => (value as Timestamp).toDate(), { toClassOnly: true })
  created: Date;

  @Type(() => Timestamp)
  @Transform(({ value }) => (value as Timestamp).toDate(), { toClassOnly: true })
  updated: Date;
}
