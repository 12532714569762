import { Injectable } from '@angular/core';
import { ShopDetailsDto } from '../dtos/shop-details.dto';
import { Firestore } from '@angular/fire/firestore';
import { Shop } from '../models/shop';
import { FirebaseApiService } from '../../core/services/firebase-api.service';
import { SearchQueryModel } from '../../core/models/search-query.model';
import { plainToInstance } from 'class-transformer';
import { ArticleService } from '../../article/services/article.service';
import { ShopArticleDto } from '../dtos/shop-article.dto';
import { toReferenceDto } from '../../core/helpers/document-reference.helper';
import { ShopArticle } from '../models/shop-article';
import { WhereQueryModel } from '../../core/models/where-query.model';

@Injectable({
  providedIn: 'root'
})
export class ShopService extends FirebaseApiService<Shop, ShopDetailsDto> {

  constructor(
    firestore: Firestore,
    private articleService: ArticleService
  ) {
    super(firestore, ShopDetailsDto, 'shops');
  }

  protected getSearchQuery(search: string): SearchQueryModel {
    const searchQuery = new SearchQueryModel();
    searchQuery.field = 'name';
    searchQuery.compareValue = search;
    return searchQuery;
  }

  toUpdateEntity(dto: ShopDetailsDto) {
    let { id, articles, ...updateDto } = dto;
    const data = plainToInstance(Shop, {
        ...updateDto,
        articles: [],
      });

    data.articles = articles.map(shopArticle => {
      const { article, ...other } = shopArticle;
      const result = plainToInstance(ShopArticle, {
        ...other
      });
      result.article = this.articleService.documentReferenceFromDto(toReferenceDto(article.id, 'articles'), 'articles');
      return Object.assign({}, result);
    });

    data.articleIds = articles.map(shopArticle => shopArticle.article.id);

    return data;
  }

  protected async transformEntityToDto(entity: Shop, id: string): Promise<ShopDetailsDto> {
    const dto = await super.transformEntityToDto(entity, id);

    dto.articles = entity.articles.map((shopArticle) => {
      const { article, ...other } = shopArticle;
      return plainToInstance(ShopArticleDto, {
        ...other,
      });
    });

    return dto;
  }

  protected async transformEntityDetailToDto(entity: Shop, id: string) {
    const dto = await this.transformEntityToDto(entity, id);

    dto.articles = await Promise.all(entity.articles.map(async (shopArticle) => {
      const { article, ...other } = shopArticle;
      const articleDto = plainToInstance(ShopArticleDto, {
        ...other,
      });
      articleDto.article = await this.articleService.get(article.id, false);
      return articleDto;
    }));

    return dto;
  }

  async findShopsWithArticle(articleId: string): Promise<ShopDetailsDto[]> {
    const where = new WhereQueryModel();
    where.field = 'articleIds';
    where.opStr = 'array-contains';
    where.compareValue = articleId;

    const result = await this.wrapper.getAllWhere(this.transformEntityDetailToDto.bind(this), where);
    return result.items;
  }
}
