import {
  addDoc,
  CollectionReference,
  doc,
  DocumentReference,
  FieldPath,
  limit,
  orderBy,
  query,
  Timestamp,
  where,
  WhereFilterOp
} from '@angular/fire/firestore';
import { DocumentReferenceWrapper } from './document-reference-wrapper';
import { PaginationDto } from '../../dtos/pagination.dto';
import { QueryWrapper } from './query-wrapper';
import { WhereQueryModel } from '../where-query.model';
import { DocumentSnapshotWrapper } from './document-snapshot-wrapper';
import { TransformFunction, TransformOneFunction } from '../transform-function.type';
import { NotFoundError } from 'rxjs';
import { BaseModel } from '../base.model';
import { SearchQueryModel } from '../search-query.model';
import { ReferenceDto } from '../../dtos/reference.dto';

/**
 * @param E entity type
 * @param DTO dto type
 */
export class CollectionWrapper<E extends BaseModel, DTO, DetailsDTO, OverviewDTO> {
  private readonly collection: CollectionReference;

  constructor(collection: CollectionReference) {
    this.collection = collection;
  }

  wrap(doc: DocumentReference): DocumentReferenceWrapper<E> {
    return new DocumentReferenceWrapper(doc);
  }

  /**
   * @see CollectionReference.add
   */
  async add(doc: E): Promise<DocumentReferenceWrapper<E>> {
    if ((doc as any).objectID !== undefined) {
      throw new Error('Object uses objectID which is already required for algolia, ' +
        'change your document structure');
    }
    doc.updated = Timestamp.now();
    const ref = await addDoc(this.collection, Object.assign({}, doc));
    return new DocumentReferenceWrapper(ref);
  }

  /**
   * @see CollectionReference.doc
   */
  doc(documentPath: string): DocumentReferenceWrapper<E> {
    return new DocumentReferenceWrapper(doc(this.collection, documentPath));
  }

  query(): QueryWrapper<E, DTO, DetailsDTO, OverviewDTO> {
    return new QueryWrapper(this, query(this.collection));
  }

  /**
   * @see CollectionReference.limit
   */
  limit(lim: number): QueryWrapper<E, DTO, DetailsDTO, OverviewDTO> {
    return new QueryWrapper(this, query(this.collection, limit(lim)));
  }

  /**
   * @see CollectionReference.orderBy
   */
  orderBy(field: string, order: 'asc' | 'desc'): QueryWrapper<E, DTO, DetailsDTO, OverviewDTO> {
    return new QueryWrapper(this, query(this.collection, orderBy(field, order)));
  }

  /**
   * @see CollectionReference.where
   */
  where(fieldPath: string | FieldPath, opStr: WhereFilterOp, value: any): QueryWrapper<E, DTO, DetailsDTO, OverviewDTO> {
    return new QueryWrapper(this, query(this.collection, where(fieldPath, opStr, value)));
  }

  async getCount(): Promise<number> {
    return this.query().count();
  }

  async getPage(
    transform: TransformFunction<E, DTO, DetailsDTO, OverviewDTO>,
    pageSize: number,
    pageNr: number,
    lastVisible?: DTO,
    sort?: string,
    search?: SearchQueryModel,
  ): Promise<PaginationDto<DTO | DetailsDTO | OverviewDTO>> {
    let query = this.query();

    if (sort) {
      const [field, order] = sort.split(',');
      if (!['asc', 'desc'].includes(order)) {
        throw new Error('invalid sort "' + sort + '" must have form: "field,[asc|desc]"');
      }
      query = query.orderBy(field, order as 'asc' | 'desc');
    }

    if (search) {
      query = query.where(search.field, '>=', search.compareValue);
      query = query.where(search.field, '<=', `${search.compareValue}\uf8ff`);
    }

    const totalItems = await query.count();

    query = query.limit(pageSize);

    if (lastVisible && pageNr > 0) {
      const doc = this.doc((lastVisible as any).id);
      const snapshot = await doc.getSnapshot();
      query = query.startAfter(snapshot);
    }

    const docs = await query.get();


    const pagination = new PaginationDto<DTO | DetailsDTO | OverviewDTO>();
    pagination.pageSize = pageSize;
    pagination.totalItems = totalItems;
    pagination.pageIndex = pageNr;
    pagination.pageCount = Math.ceil(totalItems / pageSize);
    pagination.items = await Promise.all(docs.map(async(it) => {
      return await transform(it.data(), it.id);
    }));
    return pagination;
  }

  async getAllPaged(
    transform: TransformFunction<E, DTO, DetailsDTO, OverviewDTO>,
    search?: SearchQueryModel,
    pageSize?: number,
    pageNr?: number,
    lastVisible?: DTO,
    sort?: string,
    query?: WhereQueryModel,
    type?: string): Promise<PaginationDto<DTO | DetailsDTO | OverviewDTO>> {
    pageSize = pageSize || 10;
    pageNr = pageNr || 0;
    if ((search || type) && sort) {
      throw new Error('parameter search or type and sort are not allowed at the same time');
    }
    if ((search || type || sort) && query) {
      throw new Error('parameter search/type/sort and a filter query are not allowed at the same time');
    }
    if (type) {
      // TODO implement search
      throw new Error('not implemented');
    } else if (query) {
      return await this.getAllWhere(transform, query);
    } else {
      return this.getPage(transform, pageSize, pageNr, lastVisible, sort, search);
    }
  }

  async getAllWhere(
    transform: TransformFunction<E, DTO, DetailsDTO, OverviewDTO>,
    query: WhereQueryModel
  ): Promise<PaginationDto<DTO | DetailsDTO | OverviewDTO>> {
    let values: DocumentSnapshotWrapper<E, DTO, DetailsDTO, OverviewDTO>[] = [];

    if (query.opStr == "array-contains-any" && (query.compareValue as Array<any>)?.length > 10) {
      const array = query.compareValue as Array<any>;

      for (let i = 0; i < array.length; i = i+10) {
        let q = this.where(query.field, query.opStr, array.slice(i, i+10));
        const partialValues = await q.get();
        values = [...values, ...partialValues];
      }
    }

    if (values.length <= 0) {
      let q = this.where(query.field, query.opStr, query.compareValue);
      values = await q.get();
    }

    // No actual paging, since the effective count could not be read without making a seperate read on all documents
    const pagination = new PaginationDto<DTO | DetailsDTO | OverviewDTO>();
    pagination.pageSize = values.length;
    pagination.totalItems = values.length;
    pagination.pageIndex = 0;
    pagination.pageCount = 1;
    pagination.items = await Promise.all(values.map(value => {
      return transform(value.data(), value.id);
    }));

    return pagination;
  }

  async getOne(transform: TransformOneFunction<E, DetailsDTO>, id: string) : Promise<DetailsDTO> {
    const data = await this.doc(id).get();
    if (!data) {
      throw new NotFoundError('getOne no data');
    }

    return await transform(data, id);
  }

  async getAllReferencesWhere(query: WhereQueryModel) : Promise<DocumentReferenceWrapper<E>[]> {
    let q =  this.where(query.field, query.opStr, query.compareValue);
    const data = await q.get();
    return data.map(val => val.ref);
  }

  /**
   * @deprecated do not use this method if possible!
   * Fetches all data of collection.
   */
  async getAll(lim?: number, offset?: number) : Promise<DocumentSnapshotWrapper<E,DTO,DetailsDTO,OverviewDTO>[]> {
    let q : QueryWrapper<E, DTO, DetailsDTO, OverviewDTO>;
    if (!lim || !offset) {
      q = new QueryWrapper<E, DTO, DetailsDTO, OverviewDTO>(this, this.collection);
    } else {
      q = new QueryWrapper<E, DTO, DetailsDTO, OverviewDTO>(this, query(this.collection, limit(lim)/*.offset(offset)*/));
    }

    return await q.get();
  }


  documentReferenceFromDto(ref: ReferenceDto, expectedType: string): DocumentReference {
    if (ref.type !== expectedType) {
      throw new Error(`invalid type, expected ${expectedType} but was: ${ref.type}`);
    }
    return doc(this.collection, ref.id);
  }
}
