import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { SearchField } from './components/search-field/search-field.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getPaginatorIntl } from './helpers/paginator-intl';
import { FirebaseImageDirective } from './directives/firebase-image.directive';
import { StatisticCardComponent } from './components/statistic-card/statistic-card.component';
import { MatCardModule } from '@angular/material/card';
import { ArticleTypePipe } from './pipes/article-type.pipe';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import dateFnsDe from 'date-fns/locale/de';
import { DeleteCheckDialogComponent } from './components/delete-check-dialog/delete-check-dialog.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { BadgeComponent } from './components/badge/badge.component';
import { TransactionStateComponent } from './components/transaction-state/transaction-state.component';
import { TransactionStatePipe } from './pipes/transaction-state.pipe';
import { AddonTypePipe } from './pipes/addon-type.pipe';

@NgModule({
  declarations: [
    PageLayoutComponent,
    ConfirmationDialogComponent,
    DeleteCheckDialogComponent,
    SearchField,
    FirebaseImageDirective,
    StatisticCardComponent,
    ArticleTypePipe,
    AddonTypePipe,
    ImagePreviewComponent,
    BadgeComponent,
    TransactionStateComponent,
    TransactionStatePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatDateFnsModule
  ],
  exports: [
    MatTableModule,
    MatSortModule,
    SearchField,
    FirebaseImageDirective,
    StatisticCardComponent,
    ArticleTypePipe,
    AddonTypePipe,
    ImagePreviewComponent,
    BadgeComponent,
    TransactionStateComponent,
    TransactionStatePipe
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: dateFnsDe }
  ]
})
export class CoreModule {
}
