import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { PageStateComponent } from '../enums/page-state-component.enum';
import { Sort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root'
})
export class PageStateService {

  private readonly pageSizeDefault = 10;
  private readonly pageIndexDefault = 0;

  scrollContainer: HTMLElement;

  private pageSize = this.pageSizeDefault;
  private pageIndex = this.pageIndexDefault;
  private pageIndexComponent?: PageStateComponent;

  private itemsComponent?: PageStateComponent;
  private items?: any[];

  private scrollComponent?: PageStateComponent;
  private scrollY?: number;

  private filters?: any[];
  private filterComponent?: PageStateComponent;

  private sort?: Sort;
  private sortComponent?: PageStateComponent;

  constructor() {}

  setFormFilters(componentName: PageStateComponent, form: UntypedFormGroup) {
    this.filterComponent = componentName;
    this.filters = form.value;
  }

  setSort(componentName: PageStateComponent, sort?: Sort) {
    this.sortComponent = componentName;
    this.sort = sort;
  }

  patchSort(componentName: PageStateComponent, updateFunction: (sort: Sort) => void): boolean {
    if (this.sortComponent === componentName && this.sort) {
      updateFunction(this.sort);
      return true;
    }
    this.sortComponent = undefined;
    this.sort = undefined;
    return false;
  }

  patchFormFilters(componentName: PageStateComponent, form?: UntypedFormGroup) {
    if (componentName === this.filterComponent && form) {
      if (this.filters !== undefined) {
        form.patchValue(this.filters);
      }
    } else {
      this.filters = undefined;
      this.filterComponent = undefined;
    }
  }

  setPaginator(componentName: PageStateComponent, paginator: MatPaginator) {
    this.pageIndexComponent = componentName;
    this.pageSize = paginator.pageSize;
    this.pageIndex = paginator.pageIndex;
  }

  patchPaginator(componentName: PageStateComponent, paginator: MatPaginator) {
    if (paginator) {
      paginator.pageSize = this.pageSize; // Keep page size across components
      if (this.pageIndexComponent === componentName) {
        paginator.pageIndex = this.pageIndex;
      } else {
        this.pageIndex = this.pageIndexDefault;
      }
      return;
    }
    this.pageSize = this.pageSizeDefault;
    this.pageIndex = this.pageIndexDefault;
  }

  setItems(componentName: PageStateComponent, items: any) {
    this.itemsComponent = componentName;
    this.items = items;
  }

  patchItems(componentName: PageStateComponent, updateFunction: (items: any) => void): boolean {
    if (this.itemsComponent === componentName && this.items) {
      updateFunction(this.items);
      return true;
    }
    this.itemsComponent = undefined;
    this.items = undefined;
    return false;
  }

  setScrollPosition(componentName: PageStateComponent) {
    this.scrollComponent = componentName;
    this.scrollY = this.scrollContainer.scrollTop;
  }

  patchScrollPosition(componentName: PageStateComponent) {
    if (this.scrollComponent === componentName && this.scrollY) {
      this.scrollContainer.scrollTop = this.scrollY;
    } else {
      this.scrollComponent = undefined;
    }
  }

  clear() {
    this.pageSize = this.pageSizeDefault;
    this.pageIndex = this.pageIndexDefault;
    this.pageIndexComponent = undefined;

    this.itemsComponent = undefined;
    this.items = undefined;

    this.scrollComponent = undefined;
    this.scrollY = undefined;

    this.filters = undefined;
    this.filterComponent = undefined;

    this.sort = undefined;
    this.sortComponent = undefined;
  }

}
