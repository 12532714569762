<div class="page-container" [class.mobile]="mobile">
  <mat-toolbar color="primary" class="toolbar">
    <div class="navigation">
      <button class="menu" mat-icon-button (click)="nav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <a class="logo" routerLink="/dashboard">Tankbar Admin{{ isTest ? ' TEST' : ''}}</a>
    </div>
    <div class="user" [matMenuTriggerFor]="menu">
      <button mat-icon-button *ngIf="mobile">
        <mat-icon>account_circle</mat-icon>
      </button>
      <button mat-button *ngIf="!mobile">
        <mat-icon>account_circle</mat-icon>
        <span class="name" *ngIf="user">{{ user.name || user.email }}</span>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/users/{{ user?.id }}">
          <mat-icon>settings</mat-icon>
          <span>Einstellungen</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Abmelden</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #nav class="sidenav" [mode]="mobile ? 'over' : 'side'"
                 [opened]="!mobile" [fixedInViewport]="mobile" fixedTopGap="56">
      <mat-nav-list>
        <a class="list-item" mat-list-item routerLink="/dashboard" routerLinkActive="active">
          <mat-icon matListItemIcon>space_dashboard</mat-icon>
          <div matListItemTitle>Dashboard</div>
        </a>
        <a class="list-item" mat-list-item routerLink="/purchases" routerLinkActive="active">
          <mat-icon matListItemIcon class="material-icons-outlined">shopping_cart</mat-icon>
          <div matListItemTitle class="with-counter">
            <span>Verkäufe</span>
            <span>{{ getCounter(counters?.purchases) }}</span>
          </div>
        </a>
        <a class="list-item" mat-list-item routerLink="/shops" routerLinkActive="active">
          <mat-icon matListItemIcon class="material-icons-outlined">factory</mat-icon>
          <div matListItemTitle class="with-counter">
            <span>Standorte</span>
            <span>{{ getCounter(counters?.shops) }}</span>
          </div>
        </a>
        <a class="list-item" mat-list-item routerLink="/articles" routerLinkActive="active">
          <mat-icon matListItemIcon class="material-icons-outlined">sell</mat-icon>
          <div matListItemTitle class="with-counter">
            <span>Artikel</span>
            <span>{{ getCounter(counters?.articles) }}</span>
          </div>
        </a>
        <a class="list-item" mat-list-item routerLink="/clients" routerLinkActive="active">
          <mat-icon matListItemIcon class="material-icons-outlined">person</mat-icon>
          <div matListItemTitle class="with-counter">
            <span>Kunden</span>
            <span>{{ getCounter(counters?.clients) }}</span>
          </div>
        </a>
        <mat-divider></mat-divider>
        <div mat-subheader>Einstellungen</div>
        <a class="list-item" mat-list-item routerLink="/export" routerLinkActive="active">
          <mat-icon matListItemIcon>save</mat-icon>
          <div matListItemTitle>Export</div>
        </a>
        <a class="list-item" mat-list-item routerLink="/users" routerLinkActive="active">
          <mat-icon matListItemIcon>people</mat-icon>
          <div matListItemTitle>Benutzer</div>
        </a>
        <div class="footer">
          v{{ version }}, developed by <a href="https://devedis.ch/" target="_blank" rel="noopener">devedis</a>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content #wrapper class="content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
