import { User } from '@angular/fire/auth';
import { CustomClaimsDto } from './custom-claims.dto';
import { plainToInstance, Type } from 'class-transformer';

export class UserDto {

  id: string;

  email?: string;

  name?: string;

  @Type(() => CustomClaimsDto)
  customClaims?: CustomClaimsDto;

  static fromFirebase(user: User): UserDto {
    const dto = new UserDto();
    dto.id = user.uid;
    dto.email = user.email || undefined;
    dto.name = user.displayName || undefined;
    dto.customClaims = plainToInstance(CustomClaimsDto, (user as any).customClaims as object);

    return dto;
  }
}
