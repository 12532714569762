export enum TransactionState {
  CREATE = 'CREATE',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  AUTHORIZED = 'AUTHORIZED',
  VOIDED = 'VOIDED',
  COMPLETED = 'COMPLETED',
  FULFILL = 'FULFILL',
  DECLINE = 'DECLINE',
}

export const transactionStateNames = {
  [TransactionState.CREATE]: 'Erstellt',
  [TransactionState.PENDING]: 'Ausstehend',
  [TransactionState.CONFIRMED]: 'Bestätigt',
  [TransactionState.PROCESSING]: 'Wird verarbeitet',
  [TransactionState.FAILED]: 'Fehlgeschlagen',
  [TransactionState.AUTHORIZED]: 'Authorisiert',
  [TransactionState.VOIDED]: 'Ungültig',
  [TransactionState.COMPLETED]: 'Abgeschlossen',
  [TransactionState.FULFILL]: 'Bezahlt',
  [TransactionState.DECLINE]: 'Abgelehnt',
};
