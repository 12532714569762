import { DocumentSnapshot } from '@angular/fire/firestore';
import { CollectionWrapper } from './collection-wrapper';
import { DocumentReferenceWrapper } from './document-reference-wrapper';
import { BaseModel } from '../base.model';

/**
 * @param E entity type
 */
export class DocumentSnapshotWrapper<E extends BaseModel, DTO, DetailsDTO, OverviewDTO> {

  private readonly snapshot: DocumentSnapshot;
  private readonly collection: CollectionWrapper<E, DTO, DetailsDTO, OverviewDTO>;

  constructor(collection: CollectionWrapper<E, DTO, DetailsDTO, OverviewDTO>, snapshot: DocumentSnapshot) {
    this.collection = collection;
    this.snapshot = snapshot;
  }

  get id(): string {
    return this.snapshot.id;
  }

  get path(): string {
    return this.snapshot.ref.path;
  }

  get ref(): DocumentReferenceWrapper<E> {
    return this.collection.wrap(this.snapshot.ref);
  }

  data(): E {
    return this.snapshot.data()! as any;
  }

  delete(): Promise<void> {
    const ref = this.collection.wrap(this.snapshot.ref);
    return ref.delete();
  }

}
