import { BaseModel } from '../../core/models/base.model';
import { GeoPoint, Timestamp } from '@angular/fire/firestore';
import { Transform, Type } from 'class-transformer';
import { CoordinatesDto } from '../dtos/coordinates.dto';
import { ShopArticle } from './shop-article';

export class Shop extends BaseModel {
  name: string;

  address?: string | null;

  postalCode?: string | null;

  city?: string | null;

  notes?: string | null;

  isPublic: boolean;

  cleanupDate?: Timestamp | null;

  @Type(() => CoordinatesDto)
  @Transform(({ value }) => (value as CoordinatesDto).toGeoPoint(), { toClassOnly: true })
  coordinates: GeoPoint;

  articles: ShopArticle[] = [];

  articleIds: string[];
}
