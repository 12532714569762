import { environment } from '../../../environments/environment';
import { plainToInstance } from 'class-transformer';
import { BaseDto } from '../dtos/base.dto';
import { PaginationDto } from '../dtos/pagination.dto';
import { MatSortable } from '@angular/material/sort';
import { CollectionWrapper } from '../models/firebase/collection-wrapper';
import { BaseModel } from '../models/base.model';
import { collection, DocumentReference, Firestore } from '@angular/fire/firestore';
import { SearchQueryModel } from '../models/search-query.model';
import { WhereQueryModel } from '../models/where-query.model';
import { ReferenceDto } from '../dtos/reference.dto';

export abstract class FirebaseApiService<E extends BaseModel, DTO extends BaseDto> {
  protected wrapper: CollectionWrapper<E, DTO, DTO, DTO>
  protected constructor(
    protected firestore: Firestore,
    protected dto: new (dto?: any) => DTO,
    protected readonly collectionPath: string,
  ) {
    const col = collection(this.firestore, collectionPath);
    this.wrapper = new CollectionWrapper(col);
  }

  protected async transformEntityToDto(entity: E, id: string) {
    const dto = plainToInstance(this.dto, entity);
    dto.id = id;
    return dto;
  }

  protected async transformEntityDetailToDto(entity: E, id: string) {
    return this.transformEntityToDto(entity, id);
  }

  protected getSearchQuery(search: string): SearchQueryModel | undefined {
    return undefined;
  }

  async getPaginated(pageIndex = 0, pageSize = 10, lastVisible?: DTO, sort?: MatSortable, params?: { search?: string }): Promise<PaginationDto<DTO>> {
    let sorting;
    if (sort) {
      sorting = `${sort.id},${sort.start}`;
    }

    let searchQuery: SearchQueryModel | undefined;
    const search = params?.search;
    if (search) {
      searchQuery = this.getSearchQuery(search);
      sorting = undefined;
    }

    return await this.wrapper.getAllPaged(this.transformEntityToDto.bind(this), searchQuery, pageSize, pageIndex, lastVisible, sorting, undefined, undefined);
  }

  async getAllWhere(field: string, value: any): Promise<PaginationDto<DTO>> {
    const where = new WhereQueryModel();
    where.field = field;
    where.opStr = '==';
    where.compareValue = value;

    return await this.wrapper.getAllWhere(this.transformEntityToDto.bind(this), where);
  }

  // NOTE: costly request, only used for editing shops
  async getAll(): Promise<DTO[]> {
    const data = await this.wrapper.getAll();

    return await Promise.all(data.map(async(it) => {
      return await this.transformEntityToDto(it.data(), it.id);
    }));
  }

  async get(id: string, withDetail = true): Promise<DTO> {
    return await this.wrapper.getOne(withDetail ? this.transformEntityDetailToDto.bind(this) : this.transformEntityToDto.bind(this), id);
  }

  async create(data: E): Promise<DTO> {
    const result = await this.wrapper.add(data);
    return this.transformEntityDetailToDto(await result.get(), result.id);
  }

  async update(id: string, data: E): Promise<DTO> {
    const doc = this.wrapper.doc(id);
    await doc.update(data);
    return this.transformEntityDetailToDto(await doc.get(), doc.id);
  }

  async delete(id: string): Promise<boolean> {
    const doc = this.wrapper.doc(id);
    try {
      await doc.delete();
      return true;
    } catch (e) {
      return false;
    }
  }

  async getCount(): Promise<number> {
    return this.wrapper.getCount();
  }

  documentReferenceFromDto(ref: ReferenceDto, expectedType: string): DocumentReference {
    return this.wrapper.documentReferenceFromDto(ref, expectedType);
  }
}
