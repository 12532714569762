import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PurchaseService } from '../../purchase/services/purchase.service';
import { ShopService } from '../../shop/services/shop.service';
import { ArticleService } from '../../article/services/article.service';
import { ClientService } from '../../client/services/client.service';
import { CountersDto } from '../dtos/counters.dto';

@Injectable({
  providedIn: 'root'
})
export class CountersService {

  private data: CountersDto;

  constructor(
    private http: HttpClient,
    private purchaseService: PurchaseService,
    private shopService: ShopService,
    private articleService: ArticleService,
    private clientService: ClientService
  ) {}

  private async load(): Promise<CountersDto> {
    const [shops, articles, clients, purchases, clientsLast30Days] = await Promise.all([
      this.shopService.getCount(),
      this.articleService.getCount(),
      this.clientService.getCount(),
      this.purchaseService.getCount(),
      this.clientService.getLast30DaysCount(),
    ]);

    return {
      shops,
      articles,
      clients,
      clientsLast30Days,
      purchases,
    };
  }

  async get(): Promise<Readonly<CountersDto>> {
    if (!this.data) {
      this.data = await this.load();
    }
    return this.data;
  }
}
