import { ArticleDetailsDto } from '../../article/dtos/article-details.dto';
import { Type } from 'class-transformer';

export class PurchaseLineItemDto {
  @Type(() => ArticleDetailsDto)
  base: ArticleDetailsDto;

  @Type(() => ArticleDetailsDto)
  articles: ArticleDetailsDto[];

  totalPrice: number;
}
