export class PaginationDto<T> {
  items: T[];

  totalItems: number;

  pageSize: number;

  pageIndex: number;

  pageCount: number;

  next?: number;

  previous?: number;
}
