import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageLayoutComponent } from './core/components/page-layout/page-layout.component';
import { AuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuard],
    data: {
      authGuardPipe: () => redirectLoggedInTo(['/']),
    }
  },
  {
    path: '',
    component: PageLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      authGuardPipe: () => redirectUnauthorizedTo(['/login']),
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'purchases',
        loadChildren: () => import('./purchase/purchase.module').then(m => m.PurchaseModule)
      },
      {
        path: 'shops',
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
      },
      {
        path: 'articles',
        loadChildren: () => import('./article/article.module').then(m => m.ArticleModule)
      },
      {
        path: 'clients',
        loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
      {
        path: 'export',
        loadChildren: () => import('./export/export.module').then(m => m.ExportModule)
      },
      {
        path: 'not-found',
        loadChildren: () => import('./core/pages/not-found/not-found-page.module').then(m => m.NotFoundPageModule)
      },
      {
        path: '**',
        redirectTo: '/not-found',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'ignore' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
