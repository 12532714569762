import { BaseModel } from '../../core/models/base.model';

export class Client extends BaseModel {
  email: string;
  name?: string | null;
  address?: string | null;
  city?: string | null;
  postalCode?: string | null;
  shopIds?: string[];
  hasBlender?: boolean;
}
