import { Injectable } from '@angular/core';
import { ArticleDetailsDto } from '../dtos/article-details.dto';
import { Firestore } from '@angular/fire/firestore';
import { Article } from '../models/article';
import { FirebaseApiService } from '../../core/services/firebase-api.service';
import { SearchQueryModel } from '../../core/models/search-query.model';
import { plainToInstance } from 'class-transformer';
import { deleteObject, ref, Storage, StorageReference, uploadBytes } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class ArticleService extends FirebaseApiService<Article, ArticleDetailsDto> {

  constructor(
    firestore: Firestore,
    private storage: Storage
  ) {
    super(firestore, ArticleDetailsDto, 'articles');
  }

  protected getSearchQuery(search: string): SearchQueryModel {
    const searchQuery = new SearchQueryModel();
    searchQuery.field = 'name';
    searchQuery.compareValue = search;
    return searchQuery;
  }

  toUpdateEntity(dto: ArticleDetailsDto) {
    let { id, ...updateDto } = dto;
    return plainToInstance(Article, {
      ...updateDto,
    });
  }

  async uploadImage(articleId: string, data: File): Promise<StorageReference> {
    const ext = data.name.split('.').pop();
    const reference = ref(this.storage, `/articles/image-${articleId}.${ext}`);
    const result = await uploadBytes(reference, data, {});
    return result.ref;
  }

  async deleteImage(imagePath: string): Promise<void> {
    const reference = ref(this.storage, imagePath);
    await deleteObject(reference);
  }
}
