import { Injectable } from '@angular/core';
import { PurchaseDetailsDto } from '../dtos/purchase-details.dto';
import { doc, Firestore } from '@angular/fire/firestore';
import { Purchase } from '../models/purchase';
import { FirebaseApiService } from '../../core/services/firebase-api.service';
import { SearchQueryModel } from '../../core/models/search-query.model';
import { plainToInstance } from 'class-transformer';
import { ClientService } from '../../client/services/client.service';
import { ShopService } from '../../shop/services/shop.service';
import { WhereQueryModel } from '../../core/models/where-query.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService extends FirebaseApiService<Purchase, PurchaseDetailsDto> {

  constructor(
    firestore: Firestore,
    private clientService: ClientService,
    private shopService: ShopService
  ) {
    super(firestore, PurchaseDetailsDto, 'purchases');
  }

  protected getSearchQuery(search: string): SearchQueryModel {
    const searchQuery = new SearchQueryModel();
    searchQuery.field = 'name';
    searchQuery.compareValue = search;
    return searchQuery;
  }

  protected async transformEntityToDto(entity: Purchase, id: string) {
    const dto = plainToInstance(this.dto, entity);
    dto.id = id;

    if (entity.client) {
      try {
        dto.client = await this.clientService.get(entity.client.id);
      } catch (e) {
        console.warn(e);
      }
    }

    return dto;
  }

  protected async transformEntityDetailToDto(entity: Purchase, id: string) {
    const dto = await this.transformEntityToDto(entity, id);

    if (entity.shop) {
      try {
        dto.shop = await this.shopService.get(entity.shop.id);
      } catch (e) {
        console.warn(e);
      }
    }

    return dto;
  }

  async getClientPurchases(clientId: string): Promise<PurchaseDetailsDto[]> {
    const docReference = doc(this.firestore, 'clients', clientId);
    return (await this.getAllWhere('client', docReference)).items;
  }

  async findPurchasesWithArticle(articleId: string): Promise<PurchaseDetailsDto[]> {
    const where = new WhereQueryModel();
    where.field = 'articleIds';
    where.opStr = 'array-contains';
    where.compareValue = articleId;

    const result = await this.wrapper.getAllWhere(this.transformEntityDetailToDto.bind(this), where);
    return result.items;
  }

  async getCountByShop(shopId: string): Promise<number> {
    return this.wrapper.where('shop', '==', doc(this.firestore, 'shops', shopId)).count();
  }

  async findLatestPurchaseForShop(shopId: string): Promise<PurchaseDetailsDto | undefined> {
    const data = await this.wrapper
      .where('shop', '==', doc(this.firestore, 'shops', shopId))
      .orderBy('created', 'desc')
      .limit(1)
      .get();

    if (!data.length) {
      return;
    }

    return this.transformEntityToDto(data[0].data(), data[0].id);
  }

  async getLatestPurchases(): Promise<PurchaseDetailsDto[]> {
    const sorting = `created,desc`;

    const result = await this.wrapper.getAllPaged(
      this.transformEntityDetailToDto.bind(this),
      undefined,
      10, 0, undefined,
      sorting, undefined, undefined);

    return result.items;
  }

  // NOTE: costly request, only used for export
  async getAll(): Promise<PurchaseDetailsDto[]> {
    const data = await this.wrapper.getAll();

    return await Promise.all(data.map(async(it) => {
      return await this.transformEntityDetailToDto(it.data(), it.id);
    }));
  }
}
