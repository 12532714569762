import { BaseDto } from '../../core/dtos/base.dto';
import { Exclude, Transform, Type } from 'class-transformer';
import { Timestamp } from '@angular/fire/firestore';
import { CoordinatesDto } from './coordinates.dto';
import { ShopArticleDto } from './shop-article.dto';

export class ShopDetailsDto extends BaseDto {
  name: string;

  address?: string | null;

  postalCode?: string | null;

  city?: string | null;

  notes?: string | null;

  isPublic: boolean;

  @Type(() => Timestamp)
  @Transform(({ value }) => (value as Timestamp)?.toDate(), { toClassOnly: true })
  cleanupDate?: Date | null;

  @Type(() => Timestamp)
  @Transform(({ value }) => (value as Timestamp).toDate(), { toClassOnly: true })
  created: Date;

  @Type(() => Timestamp)
  @Transform(({ value }) => (value as Timestamp).toDate(), { toClassOnly: true })
  updated: Date;

  @Type(() => Object)
  @Transform(({ value }) => {
    // TODO improve
    const dto = new CoordinatesDto();
    dto.latitude = value._lat;
    dto.longitude = value._long;
    return dto;
  }, { toClassOnly: true })
  coordinates: CoordinatesDto;

  @Exclude()
  articles: ShopArticleDto[] = [];

  get isLowStock(): boolean {
    return this.articles.some(article => article.isLowStock);
  }
}
