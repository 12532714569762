import { BaseModel } from '../../core/models/base.model';
import { ArticleType } from '../enums/article-type.enum';
import { AddonType } from '../enums/addon-type.enum';

export class Article extends BaseModel {
  type: ArticleType;
  addonType?: AddonType;
  name: string;
  description: string;
  preparationInstruction: string;
  ingredients: string;
  imagePath?: string | null;
  price: number;
  calories?: number | null;
}
