import { Environment } from "./environment.interface";
import { EnvironmentName } from './EnvironmentName';

export const environment : Environment = {
  production: true,
  name: EnvironmentName.test,
  logLevel: 'info',
  googleMapsKey: 'AIzaSyAB018-_4UnBIDLvtNGLgqzurLRZkapLsA',
  apiUrl: '',
  inAppUrl: 'https://app.smoothiebar.ch',
  walleeSpaceUrl: 'https://app-wallee.com/s/41537',
  firebase: {
    projectId: 'tankbar',
    appId: '1:398468309525:web:b7f38e83e93511dd57e41c',
    storageBucket: 'tankbar.appspot.com',
    apiKey: 'AIzaSyBTOK1UPGuym5-yBGk2wZPEjOOmqtmeQr8',
    authDomain: 'tankbar.firebaseapp.com',
    messagingSenderId: '398468309525',
  },
  firebaseRegion: 'europe-west6',
  useEmulators: false,
};
