import { DocumentReference, Timestamp } from '@angular/fire/firestore';

export class ShopArticle {

  article: DocumentReference;

  stock: number;

  refilled: Timestamp = Timestamp.now();
}
