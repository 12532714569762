import {
  deleteDoc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  PartialWithFieldValue,
  setDoc,
  Timestamp,
  updateDoc
} from '@angular/fire/firestore';
import { NotFoundError } from 'rxjs';
import { BaseModel } from '../base.model';


/**
 * @mixes DocumentReference
 */
export class DocumentReferenceWrapper<E extends BaseModel> {

  private readonly ref: DocumentReference;

  constructor(ref: DocumentReference) {
    this.ref = ref;
  }

  get id(): string {
    return this.ref.id;
  }

  get path(): string {
    return this.ref.path;
  }

  /**
   * @see DocumentReference.update
   */
  async update(data: E): Promise<void> {
    try {
      data.updated = Timestamp.now();
      await updateDoc(this.ref, Object.assign({}, data)); // implicitly checks that object exists
    } catch (e: any) {
      if (e.message.includes('NOT_FOUND')) {
        throw new NotFoundError('document not found');
      }
      throw e;
    }
  }

  /**
   * not so useful since it requires a Read operation anyway
   */
  async patch(data: PartialWithFieldValue<E>): Promise<void> {
    // Wait for firestore result, because this fails if doc doesn't exist
    await setDoc(this.ref, Object.assign({}, data) as PartialWithFieldValue<any>, { merge: true });
  }

  /**
   * @see DocumentReference.delete
   */
  async delete(): Promise<void> {
    try {
      await deleteDoc(this.ref);
    } catch (e: any) {
      if (e.message.includes('NOT_FOUND')) {
        throw new NotFoundError('document not found');
      }
      throw e;
    }
  }

  /**
   * @see DocumentReference.get
   */
  async get(): Promise<E> {
    const docSnapshot = await getDoc(this.ref);
    return docSnapshot.data()! as any;
  }

  async getSnapshot(): Promise<DocumentSnapshot> {
    return await getDoc(this.ref);
  }

}
