import { Injectable } from '@angular/core';
import { ClientDetailsDto } from '../dtos/client-details.dto';
import { collection, doc, Firestore, Timestamp } from '@angular/fire/firestore';
import { Client } from '../models/client';
import { FirebaseApiService } from '../../core/services/firebase-api.service';
import { SearchQueryModel } from '../../core/models/search-query.model';
import { plainToInstance } from 'class-transformer';
import { CollectionWrapper } from '../../core/models/firebase/collection-wrapper';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends FirebaseApiService<Client, ClientDetailsDto> {

  constructor(
    firestore: Firestore
  ) {
    super(firestore, ClientDetailsDto, 'clients');
    const col = collection(this.firestore, this.collectionPath);
    this.wrapper = new CollectionWrapper(col);
  }

  protected getSearchQuery(search: string): SearchQueryModel {
    const searchQuery = new SearchQueryModel();
    searchQuery.field = 'name';
    searchQuery.compareValue = search;
    return searchQuery;
  }

  toUpdateEntity(dto: ClientDetailsDto) {
    let { id, ...updateDto } = dto;
    return plainToInstance(Client, updateDto);
  }

  async getLast30DaysCount(): Promise<number> {
    const date = new Date();
    date.setDate(date.getDate() - 30);

    return await this.wrapper.where('created', '>=', Timestamp.fromDate(date)).count();
  }

  async getClientCountForShop(shopId: string): Promise<number> {
    return this.wrapper.where('shopIds', 'array-contains', shopId).count();
  }
}
