import { CollectionWrapper } from './collection-wrapper';
import {
  collectionSnapshots,
  DocumentSnapshot, FieldPath,
  getCountFromServer,
  limit,
  orderBy,
  query,
  Query,
  startAfter,
  where,
  WhereFilterOp
} from '@angular/fire/firestore';
import { DocumentSnapshotWrapper } from './document-snapshot-wrapper';
import { firstValueFrom } from 'rxjs';
import { BaseModel } from '../base.model';

export class QueryWrapper<E extends BaseModel, DTO, DetailsDTO, OverviewDTO> {
  private readonly collection: CollectionWrapper<E, DTO, DetailsDTO, OverviewDTO>;
  private readonly query: Query;

  constructor(collection: CollectionWrapper<E, DTO, DetailsDTO, OverviewDTO>, query: Query) {
    this.collection = collection;
    this.query = query;
  }

  /**
   * @see Query.limit
   */
  limit(lim: number): QueryWrapper<E, DTO, DetailsDTO, OverviewDTO> {
    return new QueryWrapper(this.collection, query(this.query, limit(lim)));
  }

  /**
   * @see Query.orderBy
   */
  orderBy(field: string, order: 'asc' | 'desc'): QueryWrapper<E, DTO, DetailsDTO, OverviewDTO> {
    return new QueryWrapper(this.collection, query(this.query, orderBy(field, order)));
  }

  /**
   * @see Query.where
   */
  where(
    field: string | FieldPath,
    opStr: WhereFilterOp,
    value: any
  ): QueryWrapper<E, DTO, DetailsDTO, OverviewDTO> {
    return new QueryWrapper(this.collection, query(this.query, where(field, opStr, value)));
  }

  startAfter(docSnapshot: DocumentSnapshot): QueryWrapper<E, DTO, DetailsDTO, OverviewDTO> {
    return new QueryWrapper(this.collection, query(this.query, startAfter(docSnapshot)));
  }
  /**
   * @see Query.get
   */
  async get(): Promise<DocumentSnapshotWrapper<E, DTO, DetailsDTO, OverviewDTO>[]> {
    const elements = await firstValueFrom(collectionSnapshots(this.query));
    return elements.map(it => new DocumentSnapshotWrapper(this.collection, it));
  }

  async count(): Promise<number> {
    return (await getCountFromServer(this.query)).data().count;
  }

  async deleteAll(): Promise<void> {
    const elements = await this.get();
    for (const elem of elements) {
      console.info('delete', elem.path);
      await elem.delete();
    }
  }
}
