import { BaseDto } from '../../core/dtos/base.dto';
import { Exclude, Transform, Type } from 'class-transformer';
import { Timestamp } from '@angular/fire/firestore';
import { ClientDetailsDto } from '../../client/dtos/client-details.dto';
import { ShopDetailsDto } from '../../shop/dtos/shop-details.dto';
import { PurchaseLineItemDto } from './purchase-line-item.dto';
import { TransactionState } from './transaction-state.enum';

export class PurchaseDetailsDto extends BaseDto {
  totalPrice: number;

  paymentMethod: string;

  transactionId?: number;
  transactionStatus?: TransactionState;

  @Exclude()
  client?: ClientDetailsDto;

  @Exclude()
  shop?: ShopDetailsDto;

  @Type(() => PurchaseLineItemDto)
  lineItems: PurchaseLineItemDto[] = [];

  @Type(() => Timestamp)
  @Transform(({ value }) => (value as Timestamp).toDate(), { toClassOnly: true })
  created: Date;

  @Type(() => Timestamp)
  @Transform(({ value }) => (value as Timestamp).toDate(), { toClassOnly: true })
  updated: Date;

  get name() {
    return this.lineItems?.[0]?.base?.name || 'Smoothie';
  }

  get isTransactionFulfilled() {
    return this.transactionStatus === TransactionState.FULFILL || this.transactionStatus === TransactionState.COMPLETED;
  }
}
