import { GeoPoint } from '@angular/fire/firestore';

export class CoordinatesDto {

  latitude: number;

  longitude: number;

  toGeoPoint(): GeoPoint {
    return new GeoPoint(this.latitude, this.longitude);
  }
}
